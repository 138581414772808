<template>
  <v-container>
    <v-layout row wrap justify-space-between class="mb-1">
      <v-flex xs12 sm2 md2 lg2 class="text-left py-1 px-1">
        <v-btn
          :color="$store.state.secondaryColor"
          outlined
          small
          @click="goBack"
        >
          <v-icon small left>mdi-arrow-left</v-icon>
          <span class="caption">{{ $t("back") }}</span>
        </v-btn>
      </v-flex>

      <v-spacer></v-spacer>
      <v-flex xs12 sm6 md6 lg6 class="py-2 text-right">
        <v-btn
          :color="$store.state.primaryColor"
          outlined
          small
          @click="newProduct"
        >
          {{ $t("new product") }}
        </v-btn>
      </v-flex>
    </v-layout>

    <v-divider></v-divider>

    <v-layout row wrap justify-space-between align="end" class="mt-1">
      <v-flex xs12 sm12 md4 lg4 class="text-left">
        <!--sort buttons-->

        <v-btn
          :color="
            button.selected
              ? $store.state.secondaryColor
              : $store.state.primaryColor
          "
          small
          outlined
          class="mx-1 mt-1"
          v-for="button in menu"
          :key="button.title"
          @click="sortBy(button.action)"
        >
          <span class="caption text-capitalize">{{ $t(button.title) }}</span>
        </v-btn>
      </v-flex>
      <v-spacer></v-spacer>
      <v-flex xs12 sm12 md4 lg4 v-if="!selected" class="text-left py-1 px-1">
        <v-select
          dense
          solo
          class="caption"
          :label="$t('select business')"
          item-text="businessName"
          item-value="id"
          :items="ownersBusiness"
          v-model="selectedShop"
          @change="onSelectShop"
        ></v-select>
      </v-flex>
      <v-flex xs12 sm12 md4 lg4 class="mt-1 text-right">
        <v-text-field
          v-model="search"
          dense
          solo
          :placeholder="$t('search products')"
        ></v-text-field>
      </v-flex>
    </v-layout>

    <!-- DISPLAY VIEWS : LIST AND GRID -->

    <v-layout row wrap justify-end align="end" class="">
      <v-spacer></v-spacer>
      <v-flex xs12 sm12 md4 lg4 class="text-right">
        <v-btn
          outlined
          x-small
          class="mx-2"
          @click="switchView = true"
          :color="
            switchView ? $store.state.secondaryColor : $store.state.primaryColor
          "
        >
          <v-icon>list</v-icon>
        </v-btn>
        <v-btn
          x-small
          outlined
          class="mx-2"
          @click="switchView = false"
          :color="
            !switchView
              ? $store.state.secondaryColor
              : $store.state.primaryColor
          "
        >
          <v-icon x-small>fas fa-th</v-icon>
        </v-btn>
      </v-flex>
    </v-layout>

    <!-- ############## -->
    <v-layout row wrap justify-center>
      <v-flex xs12 sm12 md12 lg12>
        <v-alert v-if="switchView">
          <v-data-table
            :headers="headers"
            :loading="loading"
            :items="filterProduct"
            :search="search"
          >
            <template v-slot:[`item.avatar`]="{ item }">
              <v-avatar size="40" @click="viewPicture(item)">
                <v-img :src="item.avatar" style="cursor: pointer">
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                      <v-progress-circular
                        width="5"
                        indeterminate
                        :color="$store.state.primaryColor"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </v-avatar>
            </template>
            <template v-slot:[`item.quantity.inStock`]="{ item }" class="">
              <v-chip
                color="white"
                class="green--text"
                v-if="Number(item.quantity.inStock) > stockQuantityNotification"
              >
                <span class="font-weight-black">
                  {{ item.quantity.inStock }}</span
                >
              </v-chip>
              <v-chip v-else color="white" class="red--text">
                <span class="font-weight-black">
                  {{ item.quantity.inStock }}</span
                >
              </v-chip>
            </template>

            <template v-slot:[`item.action`]="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    small
                    left
                    v-bind="attrs"
                    v-on="on"
                    :color="$store.state.primaryColor"
                    @click="viewProduct(item.id)"
                    >visibility</v-icon
                  >
                </template>
                <span>{{ $t("details") }}</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-alert>
        <v-alert v-else>
          <v-row>
            <v-col
              cols="12"
              md="3"
              v-for="item in filterProduct"
              :key="item.id"
            >
              <v-card @click="viewPicture(item)" width="250">
                <v-img :src="item.avatar" cover height="200">
                  <template v-slot:placeholder>
                    <v-row>
                      <v-progress-circular
                        indeterminate
                        width="2"
                        :color="$store.state.primaryColor"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
                <v-divider></v-divider>
                <v-card-actions>
                  <span class="subtitle-2">{{ item.name }}</span>
                  <v-spacer></v-spacer>
                  <span
                    class="error--text rounded py-1 px-2 text--darken-2 red lighten-5 subtitle-2"
                    >{{ currencyFormat(item.price.selling) }}</span
                  >
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </v-alert>
      </v-flex>
    </v-layout>

    <v-dialog v-model="dialog" width="400">
      <v-card dark max-height="440">
        <v-img :src="picture_selected" contain></v-img>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import db from "@/plugins/fb";

export default {
  data: () => ({
    loading: true,
    search: "",
    switchView: true,
    dialog: false,

    stockQuantityNotification: "",
    products: [],
    categories: [],
    picture_selected: "",

    businesses: [],
    previewPic:
      "https://cdn.pixabay.com/photo/2014/04/03/00/42/house-309156_960_720.png",

    selectedShop: "",
  }),

  computed: {
    selected() {
      return this.$store.getters.getCurrentBusiness;
    },
    ownersBusinessIds() {
      return this.$store.getters.getBusinessId;
    },
    ownersBusiness() {
      return this.$store.getters.getBusinesses;
    },

    filterProduct() {
      return this.products.filter((val) => {
        return (
          val.name.toLowerCase().includes(this.search.toLowerCase()) ||
          val.description.brand
            .toLowerCase()
            .includes(this.search.toLowerCase()) ||
          val.price.selling.includes(this.search)
        );
      });
    },
    headers() {
      return [
        {
          text: this.$t("product image"),
          value: "avatar",
          class: "blue-grey darken-3 white--text font-weight-medium",
        },
        {
          text: this.$t("product barcode"),
          value: "barcode",
          class: "blue-grey darken-3 white--text font-weight-medium",
        },

        {
          text: this.$t("product name"),
          value: "name",
          class: "blue-grey darken-3 white--text font-weight-regular",
          sortable: false,
        },
        {
          text: this.$t("brand"),
          value: "description.brand",
          class: "blue-grey darken-3 white--text font-weight-regular",
          sortable: false,
        },
        {
          text: this.$t("price"),
          value: "price.selling",
          class: "blue-grey darken-3 white--text font-weight-regular",
          sortable: false,
        },
        {
          text: this.$t("stock"),
          value: "quantity.inStock",
          class: "blue-grey darken-3 white--text font-weight-regular",
        },
        {
          text: this.$t("actions"),
          value: "action",
          class: "blue-grey darken-3 white--text font-weight-regular",
          sortable: false,
        },
      ];
    },
    menu() {
      return [
        {
          title: this.$t("all"),
          action: "all",
          selected: true,
        },
        {
          title: this.$t("normal stock"),
          action: "high",
          selected: false,
        },
        {
          title: this.$t("low stock"),
          action: "low",
          selected: false,
        },
      ];
    },
  },

  created() {
    this.getStock();
    this.getSettings();
  },

  methods: {
    selectShop() {
      this.$router.push({ name: "SelectBusiness" });
    },

    currencyFormat(val) {
      return this.$store.state.currency.symbol + " " + val;
    },

    getStock() {
      this.$store.commit("SET_PRODUCTS", "");
      // this.loading = true;
      if (this.selected) {
        db.collection("products")
          .where("businessID", "==", this.selected)
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              this.products.push({
                id: doc.id,
                ...doc.data(),
              });
            });

            //keep products in vuex store

            this.$store.commit("SET_PRODUCTS", this.products);
            this.loading = false;
          });
      } else {
        db.collection("products")
          .where("businessID", "in", this.ownersBusinessIds)
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              this.products.push({
                id: doc.id,
                ...doc.data(),
              });
            });

            //keep products in vuex store

            this.$store.commit("SET_PRODUCTS", this.products);
            this.loading = false;
          });
      }
    },

    getSettings() {
      db.collection("configurations")
        .doc(this.$store.state.currentUser)
        .get()
        .then((snaps) => {
          if (snaps.exists) {
            this.stockQuantityNotification =
              snaps.data().stockQuantityNotification;
          } else {
            //set 5 as minumum default
            this.stockQuantityNotification = 5;
          }
        })
        .catch(() => {
          //set 5 as minumum default
          this.stockQuantityNotification = 5;
        });
      // ######
    },

    onSelectShop() {
      this.$store.commit("SET_PRODUCTS", "");
      this.loading = true;
      this.products = [];
      db.collection("products")
        .where("businessID", "==", this.selectedShop)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            this.products.push({
              id: doc.id,
              ...doc.data(),
            });
          });

          //keep products in vuex store

          this.$store.commit("SET_PRODUCTS", this.products);
          this.loading = false;
        });
    },

    getBusinessName(id) {
      let busId = this.$store.getters.getBusinesses.find(
        (item) => item.id == id
      );
      return busId.businessName;
    },

    viewPicture(data) {
      this.picture_selected = data.avatar;
      this.dialog = true;
    },

    viewProduct(prodID) {
      this.$router.push({ name: "ViewProductDetails", params: { id: prodID } });
    },

    goBack() {
      window.history.back();
    },
    newProduct() {
      this.$router.push({ name: "EnterProduct" });
    },
    sortBy(prop) {
      this.loading = true;
      var new_array;
      //this.products.sort((a, b) => (a[prop] < b[prop] ? -1 : 1));
      if (prop == "all") {
        this.menu[0].selected = true;
        this.menu[1].selected = false;
        this.menu[2].selected = false;
        //get all products from vuex store
        this.products = this.$store.state.products;

        this.loading = false;
      } else if (prop == "high") {
        this.menu[0].selected = false;
        this.menu[1].selected = true;
        this.menu[2].selected = false;
        this.products = this.$store.state.products;
        new_array = this.products.filter(
          (element) =>
            element.quantity.inStock >= this.stockQuantityNotification
        );

        //store filtered array back into products
        this.products = new_array;

        this.loading = false;
      } else {
        this.menu[0].selected = false;
        this.menu[1].selected = false;
        this.menu[2].selected = true;
        this.products = this.$store.state.products;
        new_array = this.products.filter(
          (element) => element.quantity.inStock < this.stockQuantityNotification
        );
        //store filtered array back into products
        this.products = new_array;
        this.loading = false;
      }
    },

    // stockStatus (status) {
    //     if (status == true) return '       //     else if (status == false) return 'No'

    // },
  },
};
</script>

<style></style>
